import 'core-js/features/array/every';
import 'core-js/features/array/from';
import 'core-js/features/array/some';
import 'core-js/features/map';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/get-own-property-descriptor';
import 'core-js/features/object/get-own-property-descriptors';
import 'core-js/features/object/values';
import 'core-js/features/set';
import 'core-js/features/symbol';
if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

require('whatwg-fetch');
